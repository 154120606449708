import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal",
  tabindex: "-1",
  role: "dialog",
  ref: "modalElement",
  id: "acknowledge_modal"
}
const _hoisted_2 = {
  class: "modal-dialog",
  role: "document"
}
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-title" }
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "modal-footer justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["modal-header", $setup.styleState.headerTitleStyle])
        }, [
          _createElementVNode("h5", _hoisted_4, _toDisplayString($setup.state.title), 1),
          _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.closeModal && $setup.closeModal(...args))),
            "aria-label": "Close"
          })
        ], 2),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", {
            innerHTML: $setup.state.message
          }, null, 8, _hoisted_6)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            type: "button",
            class: _normalizeClass(["btn", $setup.styleState.btnStyle]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.closeModal()))
          }, " Ok ", 2)
        ])
      ])
    ])
  ], 512))
}