
/**
 *
 * Created by Filipe - 24/09/2022
 *
 */
import {computed, reactive} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {useStore} from "@/store";

import {User} from "@/interfaces/users";

export default {
    name: "NavbarMenu",

    setup() {

        const store = useStore();
        const route = useRoute();

        const state = reactive({
            loggedUser: computed<User | null>(() => store.getters.getLoggedUser),
            userTitle: computed<string>(() => `Hello, ${store.getters.getLoggedUser === null || store.getters.getLoggedUser === undefined ? 'User' : store.getters.getLoggedUser.name}`),
            isUserAdmin: computed<boolean>(() => store && store.getters.getLoggedUser !== null && store.getters.getLoggedUser.profiles ? store.getters.getLoggedUser.profiles.includes('ROLE_ADMIN') : false),
            isUserAdminOrManager: computed<boolean>(() => !store || store.getters.getLoggedUser === null ? false : (store.getters.getLoggedUser.profiles.includes('ROLE_ADMIN') || store.getters.getLoggedUser.profiles.includes('ROLE_MANAGER'))),
            showMenuItems: computed<boolean>(() => !!store.getters.getLoggedUser && route.name !== 'login' && route.name !== 'recoverPassword'),
        })

        const logoutHandler = () => {
            axios.post('/logout');
            window.sessionStorage.clear();
            window.location.assign('/login')
        }

        return {
            route,
            state,
            logoutHandler
        }
    }
}
