import axios from "axios";
import {
    CONTACTS_REST_URL,
    DELEGACOES_REST_URL,
    DEPARTAMENTOS_API_URL,
    ENTIDADES_API_URL,
    MOTIVOS_API_URL,
    RESPOSTAS_TIPO_API_URL,
    SITUACAO_RESPOSTAS_API_URL,
    TIPOS_API_URL
} from "@/urls";
import {ListaItem, RespostaTipoItem, UserItem} from "@/interfaces/admin";

// Add a response interceptor
axios.interceptors.response
    .use(function (response) {
        if (!window.location.pathname.includes('login'))
            if (response.request.responseURL.includes('login')) {
                console.debug('sending to login');
                window.location.assign(response.request.responseURL);
            }
        return response;
    }, function (error) {
        throw error
    });

interface State {
    tipos: ListaItem[] | null
    motivos: ListaItem[] | null
    departamentos: ListaItem[] | null
    entidades: ListaItem[] | null
    delegacoes: string[] | null
    ccUsers: UserItem[] | null
    managerAssigneeUsers: UserItem[] | null
    situacaoRespostas: ListaItem[] | null
    respostasTipo: RespostaTipoItem[] | null
}

const state: State = {
    tipos: null,
    motivos: null,
    departamentos: null,
    entidades: null,
    delegacoes: null,
    ccUsers: null,
    managerAssigneeUsers: null,
    situacaoRespostas: null,
    respostasTipo: null
}

const getters = {
    getTipos: (state: State): ListaItem[] | null => state.tipos,
    getMotivos: (state: State): ListaItem[] | null => state.motivos,
    getDepartamentos: (state: State): ListaItem[] | null => state.departamentos,
    getEntidades: (state: State): ListaItem[] | null => state.entidades,
    getDelegacoes: (state: State): string[] | null => state.delegacoes,
    getManagerAssigneeUsers: (state: State): UserItem[] | null => state.managerAssigneeUsers,
    getManagerUsers: (state: State): UserItem[] | null => !state.managerAssigneeUsers ? null : state.managerAssigneeUsers.filter((user: UserItem) => user.profile && user.profile === 'ROLE_MANAGER'),
    getManagerAdminUsers: (state: State): UserItem[] | null => !state.managerAssigneeUsers ? null : state.managerAssigneeUsers.filter((user: UserItem) => user.profile && (user.profile === 'ROLE_MANAGER' || user.profile === 'ROLE_ADMIN')),
    getCcUsers: (state: State): UserItem[] | null => state.ccUsers,
    getSituacaoRespostas: (state: State): ListaItem[] | null => state.situacaoRespostas,
    getRespostasTipo: (state: State): RespostaTipoItem[] | null => state.respostasTipo,
}

const mutations = {
    setTipos(state: State, list: ListaItem[]) {
        state.tipos = list;
    },
    setMotivos(state: State, list: ListaItem[]) {
        state.motivos = list;
    },
    setDepartamentos(state: State, list: ListaItem[]) {
        state.departamentos = list;
    },
    setEntidades(state: State, list: ListaItem[]) {
        state.entidades = list;
    },
    setDelegacoes(state: State, list: string[]) {
        state.delegacoes = list;
    },
    setCcUsers(state: State, list: UserItem[]) {
        state.ccUsers = list;
    },
    setManagerAssigneeUsers(state: State, list: UserItem[]) {
        state.managerAssigneeUsers = list;
    },
    setSituacaoRespostas(state: State, list: ListaItem[]) {
        state.situacaoRespostas = list;
    },
    setRespostasTipo(state: State, list: RespostaTipoItem[]) {
        state.respostasTipo = list;
    },

}

const actions = {

    // eslint-disable-next-line
    loadTipos(context: any) {
        return axios.get(`${TIPOS_API_URL}?size=10000&sort=name,asc`)
            .then(response => {
                // console.debug('loadTipos response ', response);
                if (response.data) {
                    context.commit('setTipos', response.data._embedded.tipoes);
                }
                return response;
            }).catch(error => {
                // console.error('loadTipos error ', error.response);
                context.commit('showAlert', {
                    title: 'Erro!',
                    type: 'error',
                    message: 'Ocorreu um erro no carregamento da lista de Tipos.',
                    helpText: error.message
                }, {root: true})
            })

    },

    // eslint-disable-next-line
    loadMotivos(context: any) {
        return axios.get(`${MOTIVOS_API_URL}?size=10000&sort=name,asc`)
            .then(response => {
                if (response.data) {
                    context.commit('setMotivos', response.data._embedded.motivoes);
                }
                return response;
            }).catch(error => {
                context.commit('showAlert', {
                    title: 'Erro!',
                    type: 'error',
                    message: 'Ocorreu um erro no carregamento da lista de Motivos.',
                    helpText: error.message
                }, {root: true})
            })

    },

    // eslint-disable-next-line
    loadDepartamentos(context: any) {
        return axios.get(`${DEPARTAMENTOS_API_URL}?size=10000&sort=name,asc`)
            .then(response => {
                if (response.data) {
                    context.commit('setDepartamentos', response.data._embedded.departamentoes);
                }
                return response;
            }).catch(error => {
                context.commit('showAlert', {
                    title: 'Erro!',
                    type: 'error',
                    message: 'Ocorreu um erro no carregamento da lista de Departamentos.',
                    helpText: error.message
                }, {root: true})
            })

    },

    // eslint-disable-next-line
    loadManagerAssigneeUsers: function (context: any) {
        return axios.get(`${CONTACTS_REST_URL}/managerAssignees`)
            .then(response => {
                if (response.data) {
                    context.commit('setManagerAssigneeUsers', response.data);
                }
                return response;
            }).catch(error => {
                context.commit('showAlert', {
                    title: 'Erro!',
                    type: 'error',
                    message: 'Ocorreu um erro no carregamento da lista de Utilizadores.',
                    helpText: error.message
                }, {root: true})
            })

    },

    // eslint-disable-next-line
    loadCcUsers: function (context: any) {
        return axios.get(`${CONTACTS_REST_URL}/ccUsers`)
            .then(response => {
                if (response.data) {
                    context.commit('setCcUsers', response.data);
                }
                return response;
            }).catch(error => {
                context.commit('showAlert', {
                    title: 'Erro!',
                    type: 'error',
                    message: 'Ocorreu um erro no carregamento da lista de Utilizadores.',
                    helpText: error.message
                }, {root: true})
            })

    },

    // eslint-disable-next-line
    loadDelegacoes(context: any) {
        return axios.get(`${DELEGACOES_REST_URL}`)
            .then(response => {
                if (response.data) {
                    context.commit('setDelegacoes', response.data);
                }
                return response;
            }).catch(error => {
                context.commit('showAlert', {
                    title: 'Erro!',
                    type: 'error',
                    message: 'Ocorreu um erro no carregamento da lista de Delegações.',
                    helpText: error.message
                }, {root: true})
            })

    },


    // eslint-disable-next-line
    loadSituacaoRespostas(context: any) {
        return axios.get(`${SITUACAO_RESPOSTAS_API_URL}?size=10000&sort=name,asc`)
            .then(response => {
                if (response.data) {
                    console.log(JSON.stringify(response.data._embedded.situacaoRespostas))
                    context.commit('setSituacaoRespostas', response.data._embedded.situacaoRespostas);
                }
                return response;
            }).catch(error => {
                context.commit('showAlert', {
                    title: 'Erro!',
                    type: 'error',
                    message: 'Ocorreu um erro no carregamento da lista de Situação de Respostas.',
                    helpText: error.message
                }, {root: true})
            })

    },

    // eslint-disable-next-line
    loadEntidades(context: any) {
        return axios.get(`${ENTIDADES_API_URL}?size=10000&sort=name,asc`)
            .then(response => {
                if (response.data) {
                    context.commit('setEntidades', response.data._embedded.entidades);
                }
                return response;
            }).catch(error => {
                context.commit('showAlert', {
                    title: 'Erro!',
                    type: 'error',
                    message: 'Ocorreu um erro no carregamento da lista de Entidades.',
                    helpText: error.message
                }, {root: true})
            })

    },

    // eslint-disable-next-line
    loadRespostasTipo(context: any) {
        return axios.get(`${RESPOSTAS_TIPO_API_URL}?size=10000&sort=titulo,asc`)
            .then(response => {
                if (response.data) {
                    context.commit('setRespostasTipo', response.data._embedded.respostaTipoes);
                }
                return response;
            }).catch(error => {
                context.commit('showAlert', {
                    title: 'Erro!',
                    type: 'error',
                    message: 'Ocorreu um erro no carregamento da lista de Respostas Tipo.',
                    helpText: error.message
                }, {root: true})
            })

    },
}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}


