
import {computed, defineComponent, reactive} from 'vue';
import {useStore} from "@/store";


/*
ALERT TYPES:
- success
- error
- warning
- info

- SHOULD PROVIDE DATA:
{
  title: 'Title',
  message: 'Message',
  type: <ALERT TYPE>,
  helpText: (optional),
}


 */
export default defineComponent({
    name: "AlertPopup",

    setup() {
        // console.log('alert popup context: %o', ctx)
        const store = useStore();
        const messagesList = computed(() => store.getters.getAlertsList)

        const state = reactive({
            title: '',
            message: '',
            helpText: ''
        })

        return {
            state,
            messagesList
        }
    }
})
