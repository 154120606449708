import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7951ebee"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal",
  tabindex: "-1",
  role: "dialog",
  ref: "modalElement",
  id: "the_modal"
}
const _hoisted_2 = {
  class: "modal-dialog",
  role: "document"
}
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-title" }
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "modal-footer justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["modal-header", _ctx.state.headerTitleStyle])
        }, [
          _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.title), 1),
          _createVNode(_component_font_awesome_icon, {
            icon: ['fas', 'xmark'],
            class: "clickable",
            onClick: _ctx.answerCancel
          }, null, 8, ["onClick"])
        ], 2),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", { innerHTML: _ctx.message }, null, 8, _hoisted_6)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-secondary",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.answerCancel && _ctx.answerCancel(...args)))
          }, " Cancelar "),
          _createElementVNode("button", {
            type: "button",
            class: _normalizeClass(["btn", _ctx.state.btnStyle]),
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.answerOk && _ctx.answerOk(...args)))
          }, _toDisplayString(_ctx.state.btnText), 3)
        ])
      ])
    ])
  ], 512))
}