import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {key, store} from './store'

import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/main.css';
import Dropzone from "dropzone";

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faArrowLeft,
    faBook,
    faCheck,
    faGears,
    faPenToSquare,
    faPlus,
    faPowerOff,
    faRightFromBracket,
    faSeedling,
    faSpinner,
    faTrash,
    faTrashCan,
    faUser,
    faUsers,
    faXmark
} from '@fortawesome/free-solid-svg-icons'
import {faFloppyDisk} from '@fortawesome/free-regular-svg-icons'

library.add(faSpinner, faPlus, faArrowLeft, faUsers, faFloppyDisk, faTrashCan, faTrash, faPenToSquare, faPowerOff, faSeedling, faUser, faGears, faBook, faCheck, faRightFromBracket, faXmark)

Dropzone.autoDiscover = false;

createApp(App)
    .use(store, key)
    .use(router)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
