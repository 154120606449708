const SPRING_DATA_URL = '/api/data'

export const USERS_API_URL = SPRING_DATA_URL + '/users';
export const TIPOS_API_URL = SPRING_DATA_URL + '/tipoes';
export const MOTIVOS_API_URL = SPRING_DATA_URL + '/motivoes';
export const DEPARTAMENTOS_API_URL = SPRING_DATA_URL + '/departamentoes';
export const ENTIDADES_API_URL = SPRING_DATA_URL + '/entidades';
export const SITUACAO_RESPOSTAS_API_URL = SPRING_DATA_URL + '/situacaoRespostas';
export const RESPOSTAS_TIPO_API_URL = SPRING_DATA_URL + '/respostaTipoes';
export const CC_USERS_API_URL = SPRING_DATA_URL + '/ccUsers';
export const CONFIGS_API_URL = SPRING_DATA_URL + '/configurations';



const SPRING_REST_URL = '/api/rest/v0'

export const USERS_REST_URL = SPRING_REST_URL + '/users';

export const CONTACTS_REST_URL = SPRING_REST_URL + '/contacts';

export const DELEGACOES_REST_URL = SPRING_REST_URL + '/socio/delegacoes';
