import {createStore, Store, useStore as baseUseStore} from 'vuex'
import axios from "axios";

import {AcknowledgeData, AlertData, DialogData} from '@/interfaces/common'
import {User} from "@/interfaces/users";
import {InjectionKey} from "vue";
import admin from "@/store/modules/admin";
import contacts from "@/store/modules/contacts";

// Add a response interceptor
axios.interceptors.response
    .use(function (response) {
        if (!window.location.pathname.includes('login'))
            if (response.request.responseURL.includes('login')) {
                console.debug('sending to login');
                window.location.assign(response.request.responseURL);
            }
        return response;
    }, function (error) {
        throw error
    });


// define injection key
export const key: InjectionKey<Store<State>> = Symbol()

// define your own `useStore` composition function
export function useStore() {
    return baseUseStore(key)
}


interface State {
    showMask: boolean
    alertid: number
    alertsList: AlertData[]
    dialogData: DialogData
    acknowledgeData: AcknowledgeData
    loggedUser: User | null
}


const state: State = {
    showMask: false,
    alertid: 0, //aux
    alertsList: [],
    dialogData: {
        message: '',
    },
    acknowledgeData: {
        message: '',
    },
    loggedUser: null
}


export const store = createStore<State>({
    state,
    getters: {
        getShowMask: (state) => state.showMask,
        getAlertsList: state => state.alertsList,
        getDialogData: state => state.dialogData,
        getAcknowledgeData: state => state.acknowledgeData,
        getLoggedUser: (state): User | null => state.loggedUser,
    },
    mutations: {
        showMask: (state, value) => {
            if (value) {
                state.showMask = true
            } else {
                state.showMask = false
            }
        },
        showAlert(state, alert: AlertData) {
            alert.id = state.alertid++
            state.alertsList.push(alert)
            setTimeout(() => {
                state.alertsList.shift()
            }, 5000)// 5000 = 5s
        },
        showDialog(state, data: DialogData) {
            if (data) {
                state.dialogData.title = data.title ? data.title : 'Tem a certeza?'
                state.dialogData.btnStyle = data.btnStyle;
                state.dialogData.message = data.message
                state.dialogData.type = data.type
                state.dialogData.btnText = data.btnText
                state.dialogData.successCallback = data.successCallback ? data.successCallback : null
                state.dialogData.show = true
            } else {
                state.dialogData.show = false
            }
        },

        showAcknowledge(state, data: AcknowledgeData) {
            if (data) {
                state.acknowledgeData.title = data.title
                state.acknowledgeData.message = data.message
                state.acknowledgeData.btnStyle = data.btnStyle
                state.acknowledgeData.type = data.type
                state.acknowledgeData.show = true
            } else {
                state.acknowledgeData.show = false
            }
        },
        setLoggedUser(state: State, user: User) {
            state.loggedUser = user
        },
    },

    actions: {
        loadWhoAmI(context) {
            return axios.get('/whoami')
                .then(response => {
                    // console.debug('Who Am I response: ', response, typeof response.data)
                    if (response.data && response.data instanceof Object) {
                        context.commit('setLoggedUser', response.data)
                    } else {
                        context.commit('setLoggedUser', null)
                    }
                    return response
                }).catch((/*error*/) => {
                    // console.error('error loading logged user ', error)
                    context.commit('setLoggedUser', null)
                })
        },
    },
    modules: {
        admin,
        contacts
    }
})
