
import {computed, defineComponent, onMounted, watch} from 'vue'
import {useStore} from "@/store";

import Modal from 'bootstrap/js/dist/modal'


export default defineComponent({
    name: "MaskModal",

    setup() {

        const store = useStore();

        let myModal: Modal;

        onMounted(() => {
            myModal = new Modal('#my_modal', {
                keyboard: false
            })
        })

        const show = computed(() => store.getters.getShowMask)
        watch(show, (show) => {
            show ? myModal.show() : myModal.hide()
        })

        return {
            show
        }

    }
})
