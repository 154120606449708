import {FilterData, Sort} from "@/utils/common";
import {Page} from "@/interfaces/common";
import {CONFIGS_API_URL} from "@/urls";
import axios from "axios";


// Add a response interceptor
axios.interceptors.response
    .use(function (response) {
        if (!window.location.pathname.includes('login'))
            if (response.request.responseURL.includes('login')) {
                console.debug('sending to login');
                window.location.assign(response.request.responseURL);
            }
        return response;
    }, function (error) {
        throw error
    });

interface State {
    filterData: FilterData | null,
    contactsPage: Page | null,
    contactsSort: Sort | null,
    limitDays: number | null,
}

const state: State = {
    filterData: null,
    contactsPage: null,
    contactsSort: null,
    limitDays: null,
}

export default {
    state,
    getters: {
        getContactsFilter: (state: State): FilterData | null => state.filterData,
        getContactsPage: (state: State): Page | null => state.contactsPage,
        getContactsSort: (state: State): Sort | null => state.contactsSort,
        getLimitDays: (state: State): number => state.limitDays ? state.limitDays : 1,
    },
    mutations: {
        setContactsFilter(state: State, data: FilterData) {
            state.filterData = data;
        },
        setContactsPage(state: State, data: Page) {
            state.contactsPage = data;
        },
        setContactsSort(state: State, data: Sort) {
            state.contactsSort = data;
        },
        setLimitDays(state: State, days: number) {
            state.limitDays = days;
        },

    },
    actions: {
        // eslint-disable-next-line
        loadLimitDaysConfig(context: any) {
            axios.get(`${CONFIGS_API_URL}/search/findFirstByConfigKey?id=LIMIT_TOTAL_DAYS`)
                .then(response => {
                    // console.debug('loadLimitDaysConfig response ', response);
                    if (response.data) {
                        context.commit('setLimitDays', Number(response.data.configValue));
                    }
                })
            /*.catch(error => {
                console.error('loadLimitDaysConfig error ', error);
            })*/

        }
    },
    namespaced: true,
}
