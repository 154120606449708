import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'

const routes: Array<RouteRecordRaw> = [

    // TODO - ADD ROUTES TO BACKEND (ViewController || ViewsConfig)
    {
        path: '/',
        name: 'home',
        redirect: (/*to*/) => {
            // the function receives the target route as the argument
            // we return a redirect path/location here.
            return {name: 'contactsList'}
        },
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            module: 'login'
        },
        component: () => import(/* webpackChunkName: "login" */ '@/views/LoginPage.vue')
    },
    {
        path: '/password-recover/:code',
        name: 'recoverPassword',
        props: true,
        meta: {
            module: 'app'
        },
        component: () => import(/* webpackChunkName: "recoverpassword" */ '@/views/RecoverPassword.vue')
    },
    {
        path: '/contacts',
        meta: {
            module: 'contacts'
        },
        component: () => import(/* webpackChunkName: "contacts" */ '@/views/contacts/ContactsModule.vue'),
        children: [
            {
                path: '',
                name: 'contactsList',
                meta: {
                    module: 'contacts'
                },
                component: () => import(/* webpackChunkName: "contacts" */ '@/views/contacts/ContactsList.vue'),
            },
            {
                path: 'registar-contacto',
                name: 'contactRegister',
                meta: {
                    module: 'contacts'
                },
                component: () => import(/* webpackChunkName: "contacts" */ '@/views/contacts/RegisterContactPanel.vue'),
            },
            {
                path: ':code/details',
                name: 'contactDetails',
                props: true,
                meta: {
                    module: 'contacts'
                },
                component: () => import(/* webpackChunkName: "contacts" */ '@/views/contacts/ContactPanelDetails.vue'),
            },

        ]
    },

    {
        path: '/reports',
        name: 'reports',
        meta: {
            module: 'reports'
        },
        component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/ReportModule.vue'),
    },

    {
        path: '/admin',
        name: 'admin',
        meta: {
            module: 'admin'
        },
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminModule.vue'),
        children: [
            {
                path: 'users',
                name: 'usersList',
                meta: {
                    module: 'admin'
                },
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/UsersList.vue')
            },
            {
                path: 'tipos',
                name: 'tiposList',
                meta: {
                    module: 'admin'
                },
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/listas/ListaTiposPanel.vue')
            },
            {
                path: 'motivos',
                name: 'motivosList',
                meta: {
                    module: 'admin'
                },
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/listas/ListaMotivosPanel.vue')
            },
            {
                path: 'departamentos',
                name: 'departamentosList',
                meta: {
                    module: 'admin'
                },
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/listas/ListaDepartamentosPanel.vue')
            },
            {
                path: 'entidades',
                name: 'entidadesList',
                meta: {
                    module: 'admin'
                },
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/listas/ListaEntidadesPanel.vue')
            },
            {
                path: 'situacao-resposta',
                name: 'situacaoRespostaList',
                meta: {
                    module: 'admin'
                },
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/listas/ListaSituacaoRespostaPanel.vue')
            },
            {
                path: 'respostas-tipo',
                name: 'respostasTipoList',
                meta: {
                    module: 'admin'
                },
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/listas/ListaRespostasTipoPanel.vue')
            },
            {
                path: 'cc-users',
                name: 'ccUsersList',
                meta: {
                    module: 'admin'
                },
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/listas/ListaCcUsersPanel.vue')
            },


        ]
    },

    {
        path: '/area-utilizador',
        name: 'userArea',
        meta: {
            module: 'userArea'
        },
        component: () => import(/* webpackChunkName: "userarea" */ '@/views/MyUserArea.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
